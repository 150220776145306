<template>
  <div class="hallOfFameWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="hallOfFameBar">
          <div class="title"><img :src="title" alt=""></div>
          <div class="hallOfFameMain">
            <div class="hallOfFameCon">
              <div class="hallOfFameMain">
                <div class="tableWrap">
                  <el-table
                    style="width: 100%"
                    stripe
                    :data="tableData">
                    <template v-for="(item,index) in tableHead[0]">
                      <el-table-column :prop="item.prop" :label="item.label" :width="item.width"></el-table-column>    <!--:min-width="(index==1 || index==2 || index==3 || index==4)?110:0"-->
                    </template>
                  </el-table>
                  <div class="pageWrap txtC">
                    <el-pagination
                      background
                      layout="total, prev, pager, next, jumper"
                      :current-page="page"
                      :page-size="size"
                      :total="total"
                      @current-change="handleCurrentChange">
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
            <div class="illustrateWrap">
              <div class="tit">规则说明：</div>
              <div class="cons">
                <p>1）每周登顶充值排行榜、特权公告排行榜、霸屏公告排行榜、超级公告排行榜、普通公告排行榜、赠花排行榜以及魅力排行榜的玩家将被录入对应名人堂，上述排行榜结算周期为周一0点到周日24点。</p>
                <p>2）名人堂更新时间为周一上午8点。</p>
                <p>3）当期名人堂玩家本周内发布公告时可启用唯一专属身份标识（普通公告名人除外）。</p>
                <p>4）名人堂相关数据永久保留展示。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import axios from "axios";
  import Cookies from "js-cookie";

  export default {
    name: 'record',
    components:{
      menuWX,
    },
    data(){
      return{
        title:require('@/assets/imgs/hallOfFame/title.png'),
        page:1,
        size:10,
        total:0,
        tableHead:[
          [
            {prop:'periodName',label:'期数',width:'70px'},
            {prop:'tab0',label:'特权公告名人',width:'130px'},
            {prop:'tab1',label:'霸屏公告名人',width:'130px'},
            {prop:'tab2',label:'超级公告名人',width:'130px'},
            {prop:'tab3',label:'普通公告名人',width:'128px'},
            {prop:'tab4',label:'赠花名人',},
            {prop:'tab5',label:'魅力名人',},
            {prop:'tab6',label:'充值名人',},
          ],
        ],
        tableData:[],
      }
    },
    created(){
      this.getHallFameData()
    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {
      getHallFameData(){
        axios({
          method: 'post',
          url: '/rank/getFamousRank',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            type:0,
            pageNum:this.page,
            pageSize:this.size,
            userId: Cookies.get('wx_userId'),      // userId
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            console.log(res.data);
            this.total = res.data.data.counts
            this.tableData = res.data.data.famousResponses.map((item,index)=>{
              let obj = {}
              obj.periodName = item.periodName
              item.usernickInfo.forEach((item2,index2)=>{
                obj['tab'+index2] = item2
              })
              return obj
            })
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },                // 获取名人堂数据
      handleCurrentChange(val){
        this.page = val
        this.getHallFameData()
      },
    }
  }
</script>

<style lang="less">
  .hallOfFameWrap{
    .hallOfFameMain{
      .el-table{
        width: 100%;
        background: none;
        border:1px solid #6276FF;
        &::before{
          background: none;
        }
        .el-table__cell{
          border: none;
        }
        tr{
          border: none;
          background: none;
          .cell{
            text-align: center;
          }
          th{
            padding:0;
            height: 60px;
            line-height: 60px;
            background: #6276FF;
            .cell{
              color: #ffffff;
              font-size: 18px;
              white-space: nowrap;
            }
          }
          td{
            padding:0;
            height: 50px;
            line-height: 50px;
            .cell{
              font-size: 14px;
            }
          }
          &:hover{
            .el-table__cell{
              background: none;
            }
            th.el-table__cell{
              background: #A042FF;
            }
          }
        }
        .el-table__row--striped{
          td.el-table__cell{
            background: rgba(160,66,255,0.1);
            border: none;
          }
        }
      }
    }
  }

</style>
<style lang="less" scoped>
  .hallOfFameWrap{
    padding-bottom: 20px;
    .hallOfFameBar{
      padding-bottom: 20px;
      .title{
        transform: translateY(13px);
        text-align: center;
      }
      .hallOfFameMain{
        .hallOfFameCon{
          margin-bottom: 20px;
          padding:10px 13px;
          border-radius: 5px;;
          background: linear-gradient(to bottom, #8D9CFF, #5768FE);
          box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255,255,255,0.25);
          .hallOfFameMain{
            padding:17px;
            border-radius: 5px;
            background: linear-gradient(to bottom, #F6F6FF, #BAC0FF);
          }
        }
        .illustrateWrap{
          padding:16px 30px 30px;
          background: #ffffff;
          box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255,255,255,0.25);
          .tit{
            margin-bottom: 8px;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 23px;
          }
          .cons{
            padding:20px;
            background: rgba(160,66,255,0.1);
            border-radius: 10px;
            line-height: 30px;
            p{
              font-size: 16px;
            }
          }
        }
        .pageWrap{
          padding-top:20px;
        }
      }
    }
  }
</style>
